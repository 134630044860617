

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'
import $ from 'jquery'
import moment from 'moment'
import _ from 'lodash'
import ListSelector from '@/edshed-common/components/ListSelector.vue'
import AvatarView from '@/components/views/AvatarView.vue'
import UseState from '@/mixins/UseState'
import { request } from '@/edshed-common/api'

@Component({ components: { AvatarView, ListSelector }, props: ['homeworkObj', 'options'] })
export default class AddHomework extends Mixins(UseState) {
  private groupData: any | null = null
  private title = 'Assignment'
  private availableFromDate: any | null = null
  private expiryDate: any | null = null
  private gamesRequired: any | null = null
  private limitLists = false
  private pupilData: any[] = [] // for all pupils
  private participants: any[] = []
  private checkedRows: any[] = []
  private showPupils = false
  private homework: any | null = null
  private group = '0'
  private isNumber = '0'
  private numberGameType = 'numberBonds'
  private isSaving = false

  private homeworkObj: any
  private options: any
  private loading = ''
  private response = ''

  private list = ''
  private listType = 0

  get dateFormat () {
    if (this._store.state!.user!.locale === 'en_US') {
      return 'MM/DD/YYYY HH:mm'
    }
    return 'DD/MM/YYYY HH:mm'
  }

  get config () {
    return {
      format: this.dateFormat,
      useCurrent: true
    }
  }

  get fromConfig () {
    return {
      format: this.dateFormat,
      useCurrent: true
    }
  }

  private onListSelected (value: { list?: string, listType?: number }) {
    console.log('onListSelected', value)

    if (typeof value.list !== 'undefined') {
      this.list = value.list
    }

    if (typeof value.listType !== 'undefined') {
      this.listType = value.listType
    }
  }

  mounted () {
    this.$nextTick(() => {
      if (this._store.getters.hasNumber && !this._store.getters.hasSpelling) {
        this.isNumber = '1'
      }
      this.getGroups()
      this.getAllPupils()
      if (this.homeworkObj != null) {
        this.homework = this.homeworkObj
        if (this.homework.stage && this.homework.stage > 0) {
          this.listType = 1
        }
        this.propsFromHw()
      }

      if (this.options != null) {
        this.listType = this.options.type
        this.list = this.options.list
      }
    })
  }

  limitSwitchDidChange (ev) {
    console.log(ev)
    this.limitLists = ev
  }

  didCheck (checkedList, row) {
    this.$nextTick(() => {
      const id = row.id
      const checked = _.filter(checkedList, { id })
      console.log(checked)
      if (checked.length === 1) {
        console.log('ADDING')
        this.addPupil(id)
      } else {
        console.log('REMOVING')
        this.removePupil(id)
      }
    })
  }

  didCheckAll (checkedList) {
    console.log('CHECK ALL')
    console.log(checkedList)
    // if length > 0 add
    // if length === 0 remove all from pupilData
    // on fail revert + show toast
  }

  restList () {
    this.list = ''
  }

  getGroups () {
    this.toggleLoading()
    this._store.commit('TOGGLE_LOADING')

    request('GET', 'users/me/school/groups', null)
      .then((response) => {
        const data = response.data
        this.groupData = data.groups
        this.groupData.sort(function (a, b) {
          if (a.name < b.name) { return -1 }
          if (a.name > b.name) { return 1 }
          return 0
        })
        this.toggleLoading()
        this._store.commit('TOGGLE_LOADING')
      })
      .catch((error) => {
        console.log(error)
        this._store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  getAllPupils () {
    this.toggleLoading()
    this._store.commit('TOGGLE_LOADING')
    request('GET', 'users/me/school/pupils', null)
      .then((response) => {
        const data = response.data
        this.pupilData = data.pupils
        this.pupilData.sort(function (a, b) {
          if (a.name < b.name) { return -1 }
          if (a.name > b.name) { return 1 }
          return 0
        })
        for (let i = 0; i < this.pupilData.length; i++) {
          this.pupilData[i].status = 'no'
          if (this.homework && this.homework.pupils) {
            const ps = this.homework.pupils.split(',')
            for (let j = 0; j < ps.length; j++) {
              if (parseInt(ps[j]) === parseInt(this.pupilData[i].id)) {
                this.pupilData[i].status = 'yes'
                this.checkedRows.push(this.pupilData[i])
              }
            }
          }
        }
        this.toggleLoading()
        this._store.commit('TOGGLE_LOADING')
      })
      .catch((error) => {
        console.log(error)
        this._store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  getPupilsForGroup (grp) {
    this.toggleLoading()
    this._store.commit('TOGGLE_LOADING')
    request('GET', 'users/me/school/groups/' + grp + '/pupils', null)
      .then((response) => {
        const data = response.data
        this.pupilData = data.pupils
        this.pupilData.sort(function (a, b) {
          if (a.name < b.name) { return -1 }
          if (a.name > b.name) { return 1 }
          return 0
        })
        for (let i = 0; i < this.pupilData.length; i++) {
          this.$set(this.pupilData[i], 'status', 'no')
          if (this.homework.pupils) {
            const ps = this.homework.pupils.split(',')
            for (let j = 0; j < ps.length; j++) {
              if (parseInt(ps[j]) === parseInt(this.pupilData[i].id)) {
                this.pupilData[i].status = 'yes'
                this.checkedRows.push(this.pupilData[i])
              }
            }
          }
        }
        this.toggleLoading()
        this._store.commit('TOGGLE_LOADING')
      })
      .catch((error) => {
        console.log(error)
        this._store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  addPupil (id) {
    for (let i = 0; i < this.pupilData.length; i++) {
      if (this.pupilData[i].id === id) {
        this.$set(this.pupilData[i], 'loading', true)
      }
    }
    request('POST', 'homework/' + this.homework.id + '/pupils/' + id, null)
      .then((response) => {
        const data = response.data
        if (data.success) {
          this.$buefy.toast.open({ container: '#addAssignmentModal', message: 'Saved', type: 'is-success' })
          for (let i = 0; i < this.pupilData.length; i++) {
            if (this.pupilData[i].id === id) {
              this.$set(this.pupilData[i], 'status', 'yes')
              this.pupilData[i].loading = false
            }
          }
          const ps = this.homework.pupils || ''
          const psa = ps.split(',')
          psa.push(id)
          this.homework.pupils = psa.join(',')
        } else {
          for (let j = 0; j < this.pupilData.length; j++) {
            if (this.pupilData[j].id === id) {
              this.$set(this.pupilData[j], 'status', 'no')
              this.pupilData[j].loading = false
            }
          }
          _.pullAllBy(this.checkedRows, [{ id }], 'id')
        }
        this.toggleLoading()
        this._store.commit('TOGGLE_LOADING')
        this.$emit('update')
      })
      .catch((error) => {
        console.log(error)
        for (let i = 0; i < this.pupilData.length; i++) {
          if (this.pupilData[i].id === id) {
            this.$set(this.pupilData[i], 'status', 'no')
            this.pupilData[i].loading = false
          }
        }
        _.pullAllBy(this.checkedRows, [{ id }], 'id')
        this._store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  removePupil (id) {
    for (let i = 0; i < this.pupilData.length; i++) {
      if (this.pupilData[i].id === id) {
        this.$set(this.pupilData[i], 'loading', true)
      }
    }
    request('DELETE', 'homework/' + this.homework.id + '/pupils/' + id, null)
      .then((response) => {
        const data = response.data
        if (data.success) {
          this.$buefy.toast.open({ container: '#addAssignmentModal', message: 'Saved', type: 'is-success' })
          for (let i = 0; i < this.pupilData.length; i++) {
            if (this.pupilData[i].id === id) {
              this.$set(this.pupilData[i], 'status', 'no')
              this.pupilData[i].loading = false
            }
          }
          const ps = this.homework.pupils || ''
          const psa = ps.split(',')
          delete psa[psa.indexOf('' + id)]
          this.homework.pupils = psa.join(',')
        } else {
          for (let j = 0; j < this.pupilData.length; j++) {
            if (this.pupilData[j].id === id) {
              this.pupilData[j].loading = false
              this.checkedRows.push(this.pupilData[j])
            }
          }
        }
        this.toggleLoading()
        this._store.commit('TOGGLE_LOADING')
        this.$emit('update')
      })
      .catch((error) => {
        console.log(error)
        for (let i = 0; i < this.pupilData.length; i++) {
          if (this.pupilData[i].id === id) {
            this.pupilData[i].loading = false
            this.checkedRows.push(this.pupilData[i])
          }
        }
        this._store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  groupSelectDidChange () {
    const val = this.group
    this.checkedRows = []
    console.log(val)
    if (parseInt(val) === 0) {
      this.getAllPupils()
    } else {
      this.getPupilsForGroup(val)
    }
  }

  addAllPupilsFromGroup () {
    for (let i = 0; i < this.pupilData.length; i++) {
      this.$set(this.pupilData[i], 'loading', true)
    }
    request('PUT', 'homework/' + this.homework.id + '/group/' + this.group, null)
      .then((response) => {
        const data = response.data
        if (data.success) {
          this.$buefy.toast.open({ container: '#addAssignmentModal', message: 'Saved', type: 'is-success' })
          const ps = this.homework.pupils || ''
          const psa = ps.split(',')

          for (let i = 0; i < this.pupilData.length; i++) {
            this.$set(this.pupilData[i], 'status', 'yes')
            this.pupilData[i].loading = false
            psa.push(this.pupilData[i].id)
          }
          this.checkedRows = _.uniqBy(this.checkedRows.concat(this.pupilData), 'id')

          // add all pupils to homework
          this.homework.pupils = _.uniq(psa).join(',')
        } else {
          for (let j = 0; j < this.pupilData.length; j++) {
            this.pupilData[j].loading = false
          }
        }
        this.toggleLoading()
        this._store.commit('TOGGLE_LOADING')
        this.$emit('update')
      })
      .catch((error) => {
        console.log(error)
        for (let i = 0; i < this.pupilData.length; i++) {
          this.pupilData[i].loading = false
        }
        this._store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  removeAllPupilsFromGroup () {
    for (let i = 0; i < this.pupilData.length; i++) {
      this.$set(this.pupilData[i], 'loading', true)
    }
    request('DELETE', 'homework/' + this.homework.id + '/group/' + this.group, null)
      .then((response) => {
        const data = response.data
        if (data.success) {
          this.$buefy.toast.open({ container: '#addAssignmentModal', message: 'Saved', type: 'is-success' })
          const ps = this.homework.pupils || ''
          let psa = ps.split(',')

          for (let i = 0; i < this.pupilData.length; i++) {
            this.$set(this.pupilData[i], 'status', 'no')
            this.pupilData[i].loading = false
            psa = _.pull(psa, '' + this.pupilData[i].id)
          }
          this.checkedRows = []

          // update homework
          this.homework.pupils = psa.join(',')
        } else {
          for (let j = 0; j < this.pupilData.length; j++) {
            this.pupilData[j].loading = false
          }
        }
        this.toggleLoading()
        this._store.commit('TOGGLE_LOADING')
        this.$emit('update')
      })
      .catch((error) => {
        console.log(error)
        for (let i = 0; i < this.pupilData.length; i++) {
          this.pupilData[i].loading = false
        }
        this._store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  saveButtonAction () {
    this.isSaving = true
    if (this.homework === null) {
      this.addHomework()
    } else {
      this.updateHomework()
    }
  }

  addHomework () {
    // validation
    let ok = true

    if (!this.title) {
      ok = false
      // this.$buefy.toast.open({container: '#addAssignmentModal', message: 'Saved', type: 'is-success'})
      this.$buefy.snackbar.open({
        container: '#addAssignmentModal',
        message: 'Title required',
        type: 'is-danger',
        position: 'is-bottom-left',
        actionText: 'Okay',
        indefinite: true
      })
    }

    if (!this.availableFromDate) {
      ok = false
      // this.$buefy.toast.open({container: '#addAssignmentModal', message: 'Saved', type: 'is-success'})
      this.$buefy.snackbar.open({
        container: '#addAssignmentModal',
        message: 'Available from date required',
        type: 'is-danger',
        position: 'is-bottom-left',
        actionText: 'Okay',
        indefinite: true
      })
    }

    if (!this.expiryDate) {
      ok = false
      // this.$buefy.toast.open({container: '#addAssignmentModal', message: 'Saved', type: 'is-success'})
      this.$buefy.snackbar.open({
        container: '#addAssignmentModal',
        message: 'Expiry date required',
        type: 'is-danger',
        position: 'is-bottom-left',
        actionText: 'Okay',
        indefinite: true
      })
    }

    if (!this.list) {
      ok = false
      // this.$buefy.toast.open({container: '#addAssignmentModal', message: 'Saved', type: 'is-success'})
      this.$buefy.snackbar.open({
        container: '#addAssignmentModal',
        message: 'Task required',
        type: 'is-danger',
        position: 'is-bottom-left',
        actionText: 'Okay',
        indefinite: true
      })
    }

    if (!ok) {
      this.isSaving = false
      return
    }

    const fr = moment(this.availableFromDate).toISOString()
    // console.log('fr' + fr)
    const ex = moment(this.expiryDate).toISOString()
    // console.log('ex' + ex)

    const props = { title: this.title, list: this.list, availableFrom: fr, expiry: ex, gamesRequired: this.gamesRequired, limitLists: this.limitLists ? '1' : '0', number: this.isNumber }
    request('POST', 'homework', props)
      .then((response) => {
        const data = response.data
        if (data.homework) {
          this.homework = data.homework
          console.log('Homework created: ' + this.homework)
          this.propsFromHw()
          this.$buefy.toast.open({ container: '#addAssignmentModal', message: 'Saved', type: 'is-success' })
          this.isSaving = false
          this.showPupils = true
        }
      })
      .catch((error) => {
        console.log(error)
        this.isSaving = false
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  updateHomework () {
    let ok = true

    if (!this.title) {
      ok = false
      // this.$buefy.toast.open({container: '#addAssignmentModal', message: 'Saved', type: 'is-success'})
      this.$buefy.snackbar.open({
        container: '#addAssignmentModal',
        message: 'Title required',
        type: 'is-danger',
        position: 'is-bottom-left',
        actionText: 'Okay',
        indefinite: true
      })
    }

    if (!this.availableFromDate) {
      ok = false
      // this.$buefy.toast.open({container: '#addAssignmentModal', message: 'Saved', type: 'is-success'})
      this.$buefy.snackbar.open({
        container: '#addAssignmentModal',
        message: 'Available from date required',
        type: 'is-danger',
        position: 'is-bottom-left',
        actionText: 'Okay',
        indefinite: true
      })
    }

    if (!this.expiryDate) {
      ok = false
      // this.$buefy.toast.open({container: '#addAssignmentModal', message: 'Saved', type: 'is-success'})
      this.$buefy.snackbar.open({
        container: '#addAssignmentModal',
        message: 'Expiry date required',
        type: 'is-danger',
        position: 'is-bottom-left',
        actionText: 'Okay',
        indefinite: true
      })
    }

    if (!this.list) {
      ok = false
      // this.$buefy.toast.open({container: '#addAssignmentModal', message: 'Saved', type: 'is-success'})
      this.$buefy.snackbar.open({
        container: '#addAssignmentModal',
        message: 'Task required',
        type: 'is-danger',
        position: 'is-bottom-left',
        actionText: 'Okay',
        indefinite: true
      })
    }

    if (!ok) {
      this.isSaving = false
      return
    }

    const fr = moment(this.availableFromDate).toISOString()
    // console.log('fr' + fr)
    const ex = moment(this.expiryDate).toISOString()
    // console.log('ex' + ex)

    const props = { title: this.title, list: this.list, availableFrom: fr, expiry: ex, gamesRequired: this.gamesRequired, limitLists: this.limitLists ? '1' : '0', number: this.isNumber }
    request('PUT', 'homework/' + this.homework.id, props)
      .then((response) => {
        const data = response.data
        console.log(data)
        this.homework = data.homework
        this.propsFromHw()
        this.isSaving = false
        this.$buefy.toast.open({ container: '#addAssignmentModal', message: 'Saved', type: 'is-success' })
        this.$emit('update')
      })
      .catch((error) => {
        console.log(error)
        this.isSaving = false
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  propsFromHw () {
    if (this.homework === null) {
      console.log('HOMEWORK NOT SET')
      return
    }
    this.list = this.homework.list_ident
    console.log('l ' + this.list)
    this.title = this.homework.title
    console.log(this.homework.available_from)
    // this.fromDate = this.dateFromServerDate(this.homework.available_from)
    this.availableFromDate = moment(this.homework.available_from).toDate()
    // this.expiry = this.dateFromServerDate(this.homework.expiry)
    this.expiryDate = moment(this.homework.expiry).toDate()
    this.gamesRequired = this.homework.number_plays
    this.limitLists = (this.homework.hide_other_lists === 1)
    this.isNumber = this.homework.type === 'number' ? '1' : '0'
    if (this.isNumber === '1') {
      const lb = this.list.split('_')
      this.numberGameType = lb[0]
      if (this.numberGameType === 'powerTenDecimals') {
        this.numberGameType = 'powerTen'
      }
      this.$nextTick(() => {
        this.list = this.homework.list_ident
        console.log(this.list)
      })
    }
  }

  dateFromServerDate (dt) {
    if (dt === null || dt === '' || !moment(dt).isValid()) {
      return null
    }
    const m = moment(dt)
    // var utcOffset = new Date().getTimezoneOffset()this.dateFormat
    // m.add({minutes: utcOffset})
    return m.format(this.dateFormat)
  }

  close () {
    this.$emit('close')
  }

  showPupilsToggle (show) {
    this.showPupils = show
  }

  toggleLoading () {
    this.loading = (this.loading === '') ? 'loading' : ''
  }
}
