






























































































































































































































































import $ from 'jquery'
import { Mixins, Component } from 'vue-property-decorator'
import { StarRating } from 'vue-rate-it' // https://craigh411.github.io/vue-rate-it/#/docs/stars
import QRCode from 'qrcode'
import { Howl } from 'howler'
import HiveGame from '@/components/views/components/HiveGame.vue'
import AddHomework from '@/components/views/components/AddHomework.vue'
import AddWord from '@/components/views/components/AddWord.vue'
import EditWordDataModal from '@/edshed-common/components/dictionary/EditWordDataModal.vue'
import { Api, GroupInfo, PhonemesInfo, Pupil, request, SpellingList, SpellingListWord, Teacher } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { symbolForPhoneme } from '@/edshed-common/phonics/index'

@Component({
  name: 'List',
  components: {
    StarRating,
    HiveGame,
    AddHomework,
    AddWord,
    EditWordDataModal
  }
})

export default class List extends Mixins(ComponentHelper) {
  loading: boolean = false
  listData: SpellingList | null = null
  listWords: SpellingListWord[] | null = null
  groupData: GroupInfo[] | null = null
  phonemesData: PhonemesInfo[] | null = null
  pupilData: Pupil[] | null = null
  teachersData: Teacher[] | null = null

  rating: number = 0
  response: string = 'Data Loading...'

  selectedFavouriteSection: string = ''

  editingWord = null
  editingData = null

  isOwner: boolean = false
  isFavourite: boolean = false
  showHiveModal: boolean = false
  showAddHomework: boolean = false
  showFavouritePopup: boolean = false
  showAddWord: boolean = false

  symbolForPhoneme = symbolForPhoneme

  mounted () {
    this.$nextTick(() => {
      if (!this.$store.getters.hasSpelling) {
        this.$router.push({ path: '/' })
        return
      }
      this.getListData()
      this.getGroupData()
      this.getPhonemes()
    })
  }

  async getPhonemes () {
    try {
      const res = await Api.getPhonemes(this.i18nLocaleFormat(this.locale))
      if (res) {
        this.phonemesData = res
      }
    } catch (err) {
    }
  }

  showAddWordModal () {
    this.showAddWord = true
  }

  hideAddWordModal () {
    this.editingWord = null
    this.showAddWord = false
    this.getListData()
  }

  playSoundForPhoneme (code) {
    if (this.listData) {
      const filename = 'https://files.edshed.com/audio/dictionary/' + this.listData.locale + '/PHONICS/mp3/' + code.toLowerCase() + '.mp3'
      const oggFile = 'https://files.edshed.com/audio/dictionary/' + this.listData.locale + '/PHONICS/ogg/' + code.toLowerCase() + '.ogg'

      // const filename = 'https://s3.eu-west-2.amazonaws.com/files.edshed.com/audio/dictionary/' + this.listData.locale + '/PHONICS/mp3/' + code.toLowerCase() + '.mp3'
      // const oggFile = 'https://s3.eu-west-2.amazonaws.com/files.edshed.com/audio/dictionary/' + this.listData.locale + '/PHONICS/ogg/' + code.toLowerCase() + '.ogg'
      // https://s3.eu-west-2.amazonaws.com/

      const sound = new Howl({
        src: [oggFile, filename]
      })

      sound.play()
    }
  }

  playAudio (word) {
    console.log(word)
    const filename = 'https://files.edshed.com/audio/dictionary/' + this.listData!.locale + '/' + word[0].toUpperCase() + '/mp3/' + word.toLowerCase() + '.mp3'
    const oggFile = 'https://files.edshed.com/audio/dictionary/' + this.listData!.locale + '/' + word[0].toUpperCase() + '/ogg/' + word.toLowerCase() + '.ogg'
    // if (this.listData.is_phonics === 1) {
    //   var letr = word.toLowerCase()
    //   if (letr === 'c') {
    //     letr = 'k'
    //   }
    //   filename = 'https://files.edshed.com/audio/dictionary/' + this.listData.locale + '/PHONICS/mp3/' + letr + '.mp3'
    //   oggFile = 'https://files.edshed.com/audio/dictionary/' + this.listData.locale + '/PHONICS/ogg/' + letr + '.ogg'
    // }
    const locale = this.listData!.locale || this.locale
    const that = this
    $.get(filename)
      .done(function () {
        // exists
        // var audio = new Audio(filename)
        // audio.play()
        const sound = new Howl({
          src: [oggFile, filename]
        })

        sound.play()
      }).fail(function () {
        // does not exists
        that.playGCWord(word, locale)
      })
  }

  playGCWord (word, locale) {
    const filename = 'https://api.edshed.com/audio/' + locale + '/' + word.toLowerCase() + '.mp3'
    const oggFile = 'https://api.edshed.com/audio/' + locale + '/' + word.toLowerCase() + '.ogg'
    console.log('GC: ' + filename)

    $.get(filename)
      .done(function () {
        $.get(oggFile)
          .done(function () {
          // exists
          // var audio = new Audio(filename)
          // audio.play()
            const sound = new Howl({
              src: [oggFile, filename],
              onloaderror: () => {
              }
            })

            sound.play()
          }).fail(function () {
            // does not exists
          })
      }).fail(function () {
        // does not exists
        const utterance = new SpeechSynthesisUtterance(word)
        const voices = window.speechSynthesis.getVoices()

        for (let i = 0; i < voices.length; i++) {
          if (voices[i].lang === locale || voices[i].lang === locale.replace('_', '-')) {
            utterance.voice = voices[i]
            break
          }
        }

        window.speechSynthesis.speak(utterance)
      })
  }
  // editErrors (word) {
  //   // sentence
  //   let errors = prompt('Enter errors', word.errors || '')
  //   console.log(errors)
  //   if (errors) {
  //     let errorsArr = errors.split(',')
  //     var trimStringArr = []
  //     for (var i = 0; i < errorsArr.length; i++) {
  //       if (errorsArr[i].trim().toLowerCase() === word.text.trim().toLowerCase()) {
  //         alert('Word cannot be in error')
  //         return
  //       }
  //       let tr = errorsArr[i].trim()
  //       trimStringArr.push(tr)
  //     }
  //     console.log(trimStringArr)
  //     request('PUT', 'lists/' + this.$route.params.ident + '/words/' + word.ident, {errors: trimStringArr.join(',')})
  //     .then(response => {
  //       var data = response.data
  //       console.log(data)
  //       this.getListData()
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       if (error.response.status === 403) {
  //         console.log('FORBIDDEN')
  //         this.$router.push('/logout')
  //       }
  //     })
  //   }
  // }
  // editChunks (word) {
  //   // sentence
  //   var chunks = prompt('Enter chunks', word.chunks || '')
  //   console.log(chunks)
  //   if (chunks) {
  //     request('PUT', 'lists/' + this.$route.params.ident + '/words/' + word.ident, {chunks: chunks})
  //     .then(response => {
  //       var data = response.data
  //       console.log(data)
  //       this.getListData()
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       if (error.response.status === 403) {
  //         console.log('FORBIDDEN')
  //         this.$router.push('/logout')
  //       }
  //     })
  //   }
  // }
  // editSentence (word) {
  //   // sentence
  //   var sentence = prompt('Enter sentence', word.sentence || '')
  //   console.log(sentence)
  //   if (sentence) {
  //     request('PUT', 'lists/' + this.$route.params.ident + '/words/' + word.ident, {sentence: sentence})
  //     .then(response => {
  //       var data = response.data
  //       console.log(data)
  //       this.getListData()
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       if (error.response.status === 403) {
  //         console.log('FORBIDDEN')
  //         this.$router.push('/logout')
  //       }
  //     })
  //   }
  // }
  // editDefinition (word) {
  //   // sentence
  //   var definition = prompt('Enter definition', word.definition || '')
  //   console.log(definition)
  //   if (definition) {
  //     request('PUT', 'lists/' + this.$route.params.ident + '/words/' + word.ident, {definition: definition})
  //     .then(response => {
  //       var data = response.data
  //       console.log(data)
  //       this.getListData()
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       if (error.response.status === 403) {
  //         console.log('FORBIDDEN')
  //         this.$router.push('/logout')
  //       }
  //     })
  //   }
  // }

  editDictionary (wordraw) {
    if (this.listData) {
      const word = Object.assign({}, wordraw)
      // console.log(word.definitions)
      const defs = '' + word.definitions
      const sent = '' + word.sentences
      console.log('defs: ' + defs)
      console.log('sents: ' + sent)
      word.word = word.text
      word.definitions = JSON.parse(defs)
      word.sentences = JSON.parse(sent)
      word.locale = this.listData.locale
      word.id = word.dictionary_id
      this.editingData = word
    }
  }

  sentencify (sen, word) {
    // swap * for <span class="has-text-danger">word</span>
    const rep = `<span class="has-text-primary"><b>${word}</b></span>`
    return sen.split('*').join(rep)
  }

  addDictionaryWord (word) {
    request('POST', 'superuser/dictionary', { word, locale: this.listData!.locale })
      .then((response) => {
        // const data = response.data
        this.getListData()
      })
      .catch((error) => {
        this.loading = false
        console.log(error)
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  editPhonics (word) {
    this.editingWord = word
    this.showAddWordModal()
    // word ident
    // var jicArr = word.text.split('')
    // var btsArr = []
    // for (var i = 0; i < jicArr.length; i++) {
    //   btsArr.push(jicArr[i].toLowerCase() + '|' + jicArr[i].toLowerCase())
    // }
    // var phonics = prompt('Enter phonics', word.phonics || btsArr.join(','))
    // console.log(phonics)
    // if (phonics) {
    //   request('PUT', 'lists/' + this.$route.params.ident + '/words/' + word.ident, {phonics: phonics})
    //   .then(response => {
    //     var data = response.data
    //     console.log(data)
    //     this.getListData()
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     if (error.response.status === 403) {
    //       console.log('FORBIDDEN')
    //       this.$router.push('/logout')
    //     }
    //   })
    // }
  }

  localeInfo (locale) {
    let loc = '<abbr title="British English">🇬🇧</abbr>'
    if (locale === 'en_US') {
      loc = '<abbr title="American English">🇺🇸</abbr>'
    } else if (locale === 'fr_FR') {
      loc = '<abbr title="French">🇫🇷</abbr>'
    } else if (locale === 'de_DE') {
      loc = '<abbr title="German">🇩🇪</abbr>'
    } else if (locale === 'es_ES') {
      loc = '<abbr title="Spanish">🇪🇸</abbr>'
    } else if (locale === 'cy_GB') {
      loc = '<abbr title="Welsh">🏴󠁧󠁢󠁷󠁬󠁳󠁿</abbr>'
    } else if (locale === 'pt_PT') {
      loc = '<abbr title="Portuguese (Portugal)">🇵🇹</abbr>'
    } else if (locale === 'pt_BR') {
      loc = '<abbr title="Portuguese (Brazil)">🇧🇷</abbr>'
    }
    return loc
  }

  getListData () {
    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')
    // var that = this
    let params: {} | null = null
    if (this.$store.state.user.superuser) {
      params = { dictionary: true }
    }
    request('GET', 'lists/' + this.$route.params.ident, params)
      .then((response) => {
        const data = response.data
        this.listData = data.list
        this.listWords = data.list.words
        this.isFavourite = (data.list.fav === '1')
        this.rating = parseFloat(data.list.rating)
        if (this.listData && this.listData.owner === this.$store.state.user.username) {
          this.isOwner = true
        }
        // console.log('readerData: ' + this.readerData)
        if (!this.listWords || (this.listWords.length === 0)) {
          this.response = 'No Words'
        }
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()

        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        this.response = 'Details incorrect'
      })
  }

  getGroupData () {
    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')
    // var that = this
    const url = this.$store.state.user.school.admin ? 'users/me/school/groups' : 'users/me/groups'

    request('GET', url, null)
      .then((response) => {
        const data = response.data
        this.groupData = data.groups

        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()

        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        this.response = 'Details incorrect'
      })
  }

  renderQRCode () {
    if (this.listData && this.listWords) {
      $('#canvas').toggle()
      let codeString = 'spellingshed:' + this.listData.title + '~' + this.listData.ident + '|'
      for (let i = 0; i < this.listWords.length; i++) {
        codeString = codeString + this.listWords[i].text + ','
      }
      // console.log(codeString)
      QRCode.toCanvas(document.getElementById('canvas'), codeString, function (error) {
        if (error) {
          console.error(error)
        }
        // console.log('QR success!')
      })
    }
  }

  addFavourite () {
    request('PUT', 'lists/' + this.listData!.ident + '/favourite', null)
      .then(() => {
        this.getListData()
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()

        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        this.response = 'Details incorrect'
      })
  }

  removeFavourite () {
    const confirm = window.confirm('Are you sure you want to remove this list?')
    if (confirm) {
      request('DELETE', 'lists/' + this.listData!.ident + '/favourite', null)
        .then(() => {
          this.getListData()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    }
  }

  addWord () {
    if (this.listData!.is_phonics) {
      this.showAddWordModal()
    } else {
      let newWord = prompt('Enter new word', '')
      if (newWord != null) {
        newWord = newWord.replace(/‘/g, '\'')
        request('POST', 'lists/' + this.$route.params.ident + '/words', { words: [newWord] })
          .then(() => {
            this.getListData()
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    }
  }

  deleteWord (w) {
    console.log(w.text)
    const confirm = window.confirm('Are you sure you want to delete this word?')
    if (confirm) {
      request('DELETE', 'lists/' + this.listData!.ident + '/words/' + w.ident, null)
        .then(() => {
          this.getListData()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    }
  }

  sendRating (rating) {
    request('PUT', 'lists/' + this.listData!.ident + '/rate', { rating })
      .then(() => {
        this.getListData()
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  viewQR () {
    this.renderQRCode()
  }

  showHive () {
    this.showHiveModal = true
  }

  hideHive () {
    this.showHiveModal = false
  }

  showHomework () {
    if (this.listData!.scheme || parseInt(this.listData!.fav) || this.isOwner) {
      this.showAddHomework = true
    } else {
      const c = confirm(this.$store.state.user.locale === 'en_US' ? 'Assignment lists must be your own, a favorite or a curriculum list.' : 'Assignment lists must be your own, a favourite or a scheme list. Favourite list?')
      if (c) {
        this.addFavourite()
      }
    }
  }

  hideHomework () {
    this.showAddHomework = false
  }

  favouriteForAllTeachers () {
    request('PUT', 'lists/' + this.listData!.ident + '/favourite/allteachers', null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  favouriteForTeacher (id) {
    request('PUT', 'lists/' + this.listData!.ident + '/favourite/teachers/' + id, null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  favouriteForAllPupils () {
    request('PUT', 'lists/' + this.listData!.ident + '/favourite/allpupils', null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  favouriteForPupil (id) {
    request('PUT', 'lists/' + this.listData!.ident + '/favourite/pupils/' + id, null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  favouriteForGroup (groupId) {
    request('PUT', 'lists/' + this.listData!.ident + '/favourite/group/' + groupId, null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  unfavouriteForAllTeachers () {
    request('DELETE', 'lists/' + this.listData!.ident + '/favourite/allteachers', null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  unfavouriteForTeacher (id) {
    request('DELETE', 'lists/' + this.listData!.ident + '/favourite/teachers/' + id, null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  unfavouriteForAllPupils () {
    request('DELETE', 'lists/' + this.listData!.ident + '/favourite/allpupils', null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  unfavouriteForPupil (id) {
    request('DELETE', 'lists/' + this.listData!.ident + '/favourite/pupils/' + id, null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  unfavouriteForGroup (groupId) {
    request('DELETE', 'lists/' + this.listData!.ident + '/favourite/group/' + groupId, null)
      .then(() => {
        // this.getListData()
        this.hideFavouriteModal()
        alert('Success')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  showFavouriteModal () {
    this.showFavouritePopup = true
  }

  hideFavouriteModal () {
    this.showFavouritePopup = false
  }

  setActiveFavouriteSection (sec) {
    this.pupilData = null
    this.selectedFavouriteSection = sec
    if (sec === '~teachers') {
      this.loadTeachers()
    } else if (sec !== '~pupils') {
      this.loadPupilsForGroup(sec)
    }
  }

  loadPupilsForGroup (groupId) {
    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')
    request('GET', 'users/me/school/groups/' + groupId + '/pupils', null)
      .then((response) => {
        const data = response.data
        this.pupilData = data.pupils

        console.log('pupilData: ' + data.pupils)
        if (!this.pupilData || this.pupilData.length === 0) {
          this.response = 'No Groups'
        }
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()

        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        this.response = 'Details incorrect'
      })
  }

  loadTeachers () {
    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')
    request('GET', 'users/me/school/teachers', null)
      .then((response) => {
        const data = response.data
        this.teachersData = data.teachers

        // console.log('readerData: ' + this.readerData)
        if (!this.teachersData || this.teachersData.length === 0) {
          this.response = 'No Teachers'
        }
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()

        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        this.response = 'Details incorrect'
      })
  }

  toggleLoading () {
    this.loading = !this.loading
  }

  // computed
  get isSuperuser () {
    return parseInt(this.$store.state.user.superuser) === 1
  }

  get homeworkOptions () {
    if (this.listData) {
      const opts = { list: this.listData.ident, type: this.listData.scheme ? 1 : 0 }
      return opts
    }
  }
}
