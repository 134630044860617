import { Component, Vue } from 'vue-property-decorator'
import { Store } from 'vuex/types/index'
import mutations from '@/store/mutations'
import { Getters, State } from '@/store/types'

type _Getters = {
  [P in keyof Getters]: Getters[P] extends (s: State) => infer R ? R : never
}

/** This works by removing the `getters` and `commit` property and replacing them with statically typed versions */
type StoreType = Omit<Store<State>, 'getters' | 'commit'> & {
  getters: _Getters,
  commit: <TMutation extends keyof typeof mutations> (
    mutation: TMutation,
    payload?: typeof mutations[TMutation] extends (s: State, p: infer P) => void ? P : never
  ) => void
}
@Component
export default class UseState extends Vue {
  /** Statically typed version of the store (alias for this.$store) */
  public get _store (): StoreType {
    return this.$store
  }
}
