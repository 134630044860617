






















































































































































































/* eslint-disable indent */
import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import debounce from 'lodash/debounce'
import { Api, SpellingList } from '../api'
import ComponentHelperBase from '../mixins/ComponentHelperBase'

@Component({
  components: {
    StarRating: async () => {
      // @ts-ignore
      if (!process.server) {
        // @ts-ignore
        return (await import('vue-rate-it')).StarRating // https://craigh411.github.io/vue-rate-it/#/docs/stars
      } else {
        return (await import('./Empty.vue')).default
      }
    }
  }
})
export default class ListSelector extends Mixins(ComponentHelperBase) {
  @Prop(String) readonly listIdentProp: string | undefined
  @Prop({ default: -1 }) readonly stageProp!: number
  @Prop({ default: null }) readonly categoryProp!: string | null
  @Prop({ default: null }) readonly variantProp!: string | null
  @Prop({ default: true }) readonly showLists!: boolean
  @Prop({ default: true }) readonly showVariants!: boolean
  @Prop({ default: true }) readonly showListTypes!: boolean
  @Prop({ default: true }) readonly showTitle!: boolean
  @Prop({ default: false }) readonly disabled!: boolean

  private stage: number = -1
  private usersLists: SpellingList[] = []
  private usersFavouriteLists: SpellingList[] = []
  private schemeVariants: string[] = []

  private activeSchemeVariant = 'spellingshed'
  private schemeStageCategory: string | null = null
  private variantListData: SpellingList[] = []
  private searchResults: SpellingList[] = []
  private selectedSearchResult: string | null = null

  @Watch('activeSchemeVariant')
  onVariantChanged (value: string, _oldValue: string) {
    this.$emit('variant-changed', value)
  }

  @Watch('stage')
  onStageChanged (value: string, _oldValue: string) {
    this.$emit('stage-changed', value)
  }

  @Watch('schemeStageCategory')
  onCategoryChanged (value: string, _oldValue: string) {
    this.$emit('category-changed', value)
  }

  private response = ''

  // These watch the incoming properties and changes to them will emit change events
  private list: SpellingList | null = null
  private listType: number = 1
  private allowListTypeChoice: boolean = true

  private isFetchingSearch: boolean = false

  get shouldShowListSelector () {
    if (this.showLists === false) {
      return false
    }

    switch (this.listType) {
      case 1:
        return this.filteredSchemeListData && this.filteredSchemeListData.length > 0 && (this.schemeVariantStageHasCategories ? this.schemeStageCategory !== null : true)
      case 2:
        return this.usersLists && this.usersLists.length > 0
      case 3:
        return false
      case 4:
        return this.usersFavouriteLists && this.usersFavouriteLists.length > 0
    }
  }

  get currentFilteringLists () {
    switch (this.listType) {
      case 1:
        return this.filteredSchemeListData
      case 2:
        return this.usersLists
      case 4:
        return this.usersFavouriteLists
    }
  }

  get filteredSchemeListData () {
    if (this.variantListData.length === 0) {
      return null
    }

    if (!this.schemeVariantHasStages) {
      return this.variantListData
    } else if (!this.schemeVariantStageHasCategories) {
      return this.variantListData.filter(l => l.stage === this.stage).sort((a, b) => a.list - b.list)
    } else {
      return this.variantListData.filter(l => l.stage === this.stage && l.stage_category === this.schemeStageCategory).sort((a, b) => a.list - b.list)
    }
  }

  get schemeVariantHasStages () {
    return this.schemeStages && this.schemeStages.length > 1
  }

  get schemeVariantStageHasCategories () {
    return this.schemeStageCategories && this.schemeStageCategories.length > 1
  }

  get schemeStageCategories () {
    if (!this.variantListData || this.stage === -1) {
      return null
    }
    const categories = this.variantListData.filter(l => (l.stage === this.stage) && (l.stage_category !== null)).map(l => l.stage_category!.trim()).filter(this.onlyUnique)
    if (this.variantListData[0] && this.variantListData[0].scheme_region && this.variantListData[0].scheme_region.trim() === 'en_ZA') {
      return categories.sort((a, b) => (a || '').localeCompare(b || ''))
    }
    return categories
  }

  get schemeStages () {
    if (!this.variantListData) {
      return null
    }
    const stages = this.variantListData.map(l => l.stage).filter(this.onlyUnique)
    return stages
  }

  public mounted () {
    this.$watch('listIdentProp', (_) => {
      this.setPreselectedList()
    })

    if (this.stageProp) {
      this.stage = this.stageProp
    }
    this.$watch('stageProp', (value) => {
      this.stage = value
    })

    this.$watch('categoryProp', (value) => {
      this.schemeStageCategory = value
    })

    this.$watch('variantProp', (value) => {
      this.activeSchemeVariant = value
    })

    this.$watch('list', (list) => {
      this.emitListChanged({ list })
    })

    this.$watch('listType', (listType: number) => {
      this.emitListTypeChanged({ type: listType })
    })

    this.$watch('filteredSchemeListData', (lists: SpellingList[]) => {
      this.$emit('lists-changed', lists)
    })

    this.$nextTick(async () => {
      await this.getUsersListData()
      await this.getFavouriteListData()
      await this.getSchemeVariants()
      if (this.listIdentProp) {
        await this.setPreselectedList()
      } else {
        this.activeSchemeVariant = this.variantProp ?? 'spellingshed'
        await this.getSchemeVariantListData()
        this.listType = 1
      }
    })
  }

  public searchListsDebounced = debounce((name) => {
    this.searchLists(name)
  }, 500)

  public searchLists (term: string) {
    if (term.length < 3) {
      return
    }

    this.isFetchingSearch = true
    Api.searchLists({ q: term, locale: this.$store.state.user.school.locale }, undefined)
      .then((response) => {
        const lists = response.map(this.numberIfy)
        this.searchResults = lists
        this.searchResults.sort((a, b) => a.title > b.title ? 1 : 0)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)

        if (error.response && error.response.status === 403) {
          // eslint-disable-next-line no-console
          console.log('FORBIDDEN')
          this.$router.push({ name: 'Logout' })
        }

        this.response = 'Details incorrect'
      })
      .finally(() => {
        this.isFetchingSearch = false
      })
  }

  public numberIfy (item: any, _: number) {
    item.rating = parseFloat(item.rating)
    return item
  }

  public searchResultSelected (option: SpellingList) {
    this.list = option
  }

  public userSelectedList (value: SpellingList) {
    this.list = value
  }

  public async loadList () {
    if (!this.listIdentProp || this.listIdentProp === '') {
      return
    }

    try {
      this.list = await Api.getListDetailed(this.listIdentProp)
      this.activeSchemeVariant = this.list.scheme_variant!
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  public async setPreselectedList () {
    if (this.list && this.list.ident === this.listIdentProp) {
      return
    }

    await this.loadList()

    if (!this.list) {
      return
    }

    this.activeSchemeVariant = this.list.scheme_variant!
    this.stage = this.list.stage

    if (this.activeSchemeVariant && this.activeSchemeVariant !== '') {
      await this.getSchemeVariantListData()

      const isSchemeList = this.variantListData.filter(p => p.ident === this.listIdentProp)

      if (isSchemeList && isSchemeList.length > 0) {
        this.listType = 1
        this.stage = isSchemeList[0].stage
        this.schemeStageCategory = isSchemeList[0].stage_category
        this.list = isSchemeList[0]
      }
    } else {
      const isUsersList = this.usersLists.filter(l => l.ident === this.listIdentProp)

      if (isUsersList && isUsersList.length > 0) {
        this.listType = 2
        this.list = isUsersList[0]
      } else {
        this.listType = 3
        this.selectedSearchResult = this.list.title
      }
    }
  }

  public onlyUnique (value: any, index: number, self: any) {
    return self.indexOf(value) === index
  }

  private getSchemeVariantTitle (variant: string) {
    switch (variant) {
      case 'spellingshed':
        return 'Spelling Shed'
      case 'spellingshed-new':
        return 'Spelling Shed New'
      case 'animals':
        return 'Animals TEST'
      case 'dolch':
        return 'Dolch'
      case 'uil':
        return 'UIL'
      case 'uil34':
        return 'UIL Grades 3-4'
      case 'uil56':
        return 'UIL Grades 5-6'
      case 'uil78':
        return 'UIL Grades 7-8'
      case 'nzcer':
        return 'NZCER'
      case 'oxford':
        return 'Oxford Wordlists'
      case 'fry':
        return 'Fry'
      case 'spellingshed2020':
        return 'Spelling Shed 2020'
      case 'spellingshed2021':
        return 'Spelling Shed 2021'
      case 'phonics':
        return 'Spelling Shed Phonics'
      case 'journeys':
        return 'Journeys'
      case 'wonders':
        return 'Wonders'
      default:
        return variant
    }
  }

  private async getSchemeVariants () {
    const varnts = await Api.getListSchemeVariants(this.$store.state.user.school.locale)

    this.schemeVariants = varnts.filter(a => a !== 'uil' && a !== 'uil34' && a !== 'spellingshed2020' && a !== 'uil56' && a !== 'uil78').sort((a, b) => {
      if (a === 'spellingshed') {
        return -1
      } else if (a === 'phonics' || b === 'phonics') {
        return 1
      }
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0
      })
  }

  private async getUsersListData () {
    this.usersLists = []

    try {
      const lists = await Api.getUserLists()

      const data = lists.map(numberIfy)
      data.sort((a, b) => {
        if (a.title < b.title) { return -1 }
        if (a.title > b.title) { return 1 }
        return 0
      })
      this.usersLists = data
    } catch (err) {
      this.response = 'Details incorrect'
      // eslint-disable-next-line no-console
      console.error('getUsersListData', err)
    }
  }

  private async getFavouriteListData () {
    try {
      this.usersFavouriteLists = (await Api.getUserListFavourites()).map(numberIfy)
    } catch (err) {
      this.response = 'Details incorrect'
      // eslint-disable-next-line no-console
      console.error('getFavouriteListData', err)
    }
  }

  private async getSchemeVariantListData () {
    try {
      const lists = await Api.getListSchemeVariantLists(this.$store.state.user.school.locale, this.activeSchemeVariant)

      this.variantListData = lists.map(numberIfy)

      if (!this.usersLists || this.usersLists.length === 0) {
        this.response = 'No Lists'
      }
    } catch (err) {
      this.response = 'Details incorrect'
      // eslint-disable-next-line no-console
      console.error('getSchemeVariantListData', err)
    }
  }

  private listStageDidChange () {
    this.list = null
    this.schemeStageCategory = null
  }

  private listStageCategoryDidChange () {
    this.list = null
  }

  private listTypeDidChange (ev: string) {
    // eslint-disable-next-line no-console
    console.log('LIST UNSET')
    this.listType = parseInt(ev, 10)
    this.list = null

    this.stage = -1
    this.schemeStageCategory = ''
    this.activeSchemeVariant = 'spellingshed'
  }

  private setActiveVariant (variant: string) {
    this.activeSchemeVariant = variant
    this.stage = -1
    this.variantListData = []
    this.getSchemeVariantListData()
    this.list = null
  }

  private emitListChanged (value: { list: SpellingList | null }) {
    this.$emit('change', value)
  }

  private emitListTypeChanged (value: { type: number }) {
    this.$emit('change-type', value)
  }
}

function numberIfy<T extends { rating: string | number }> (item: T, _: number) {
  item.rating = parseFloat(String(item.rating))
  return item
}
