











































































import { Mixins, Prop } from 'vue-property-decorator'
import { Api, PhonemesInfo, request, SpellingListWord } from '@/edshed-common/api'
import { Howl } from 'howler'
import ComponentHelper from '@/mixins/ComponentHelper'
import { allGraphemes, symbolForPhoneme } from '../../../edshed-common/phonics/index'

export default class AddWordView extends Mixins(ComponentHelper) {
  @Prop() readonly word!: SpellingListWord
  @Prop() readonly listIdent!: string

  activeWord: string | null = null
  phonicsData: string[] = []
  phonemesData: PhonemesInfo[] | null = null
  phonicsComplete: boolean = false
  symbolForPhoneme = symbolForPhoneme

  mounted () {
    this.$nextTick(() => {
      this.activeWord = this.word ? this.word.text : ''
      this.phonicsData = this.word ? this.word.phonics.split(',') : []
      this.calcPhonicsComplete()
    })
    this.getPhonemes()
  }

  async getPhonemes () {
    try {
      const res = await Api.getPhonemes(this.i18nLocaleFormat(this.locale))
      if (res) {
        this.phonemesData = res
      }
    } catch (err) {
      console.log(err)
    }
  }

  calcPhonicsComplete () {
    let t = ''
    let ok = true
    for (const p of this.phonicsData) {
      const sp = p.split('|')
      t += sp[0]
      if (!sp[0] || !sp[1]) {
        // console.log('error')
        ok = false
      }
    }
    if (t) { // split digraphs
      let u = ''
      for (let i = 0; i < t.length; i++) {
        if (t[i] !== '_') {
          u += t[i]
        } else {
          u += t[i + 2]
          i++
          u += t[i]
          i++
        }
      }

      t = u
    }
    console.log('t: ' + t)
    this.phonicsComplete = (t.toLowerCase() === this.activeWord!.toLowerCase() && t !== '' && ok)
  }

  wordDidChange (value) {
    this.activeWord = value
    this.calcPhonicsComplete()
  }

  inputDidChange (event) {
    // console.log(event)
    // console.log('val: ' + event.srcElement.value)
    // console.log('ind: ' + event.srcElement.id.split('-')[1])
    const index = event.srcElement.id.split('-')[1]
    const value = event.srcElement.value.toLowerCase().trim()
    const original = this.phonicsData[index]
    const originalSplit = original.split('|')
    this.phonicsData[index] = value + '|' + originalSplit[1]
    this.calcPhonicsComplete()
    this.validateGraphemePhoneme(this.phonicsData[index])
  }

  selectDidChange (event) {
    // console.log('val: ' + event.srcElement.value)
    // console.log('ind: ' + event.srcElement.id.split('-')[1])
    const index = event.srcElement.id.split('-')[1]
    const value = event.srcElement.value
    const original = this.phonicsData[index]
    const originalSplit = original.split('|')
    this.phonicsData[index] = originalSplit[0] + '|' + value
    const target = event.target || event.srcElement
    if (!this.validateGraphemePhoneme(this.phonicsData[index])) {
      target.parentElement.classList.add('is-danger')
    } else {
      target.parentElement.classList.remove('is-danger')
    }
    this.$forceUpdate()
    this.calcPhonicsComplete()
  }

  validateGraphemePhoneme (val) {
    const entries = allGraphemes.filter(v => v === val)
    console.log(entries.length)
    return entries.length > 0
  }

  addBlock () {
    this.phonicsData.push('|')
    this.calcPhonicsComplete()
  }

  removePhonicIndex (index) {
    this.phonicsData.splice(index, 1)
    this.calcPhonicsComplete()
  }

  autoFill () {
    if (!this.activeWord) {
      return
    }
    const jicArr = this.activeWord.split('')
    const btsArr: string[] = []
    for (let i = 0; i < jicArr.length; i++) {
      const g = jicArr[i].toLowerCase()
      let p = jicArr[i].toLowerCase()
      if (p === 'c') {
        p = 'k'
      }
      btsArr.push(g + '|' + p)
    }
    this.phonicsData = btsArr
    this.calcPhonicsComplete()
  }

  playSoundForPhoneme (code, onEnd) {
    if (!code) {
      return
    }
    let locale = this.locale
    if (locale === 'en_NZ') {
      locale = 'en_AU'
    }
    if (locale === 'en_IE') {
      locale = 'en_GB'
    }
    const filename = 'https://files.edshed.com/audio/dictionary/' + locale + '/PHONICS/mp3/' + code.toLowerCase() + '.mp3'
    const oggFile = 'https://files.edshed.com/audio/dictionary/' + locale + '/PHONICS/ogg/' + code.toLowerCase() + '.ogg'

    const sound = new Howl({
      src: [oggFile, filename]
    })
    if (onEnd) {
      sound.onend = onEnd
    }
    sound.play()
  }

  closeView () {
    this.$emit('close')
  }

  saveWord () {
    let method: 'POST' | 'PUT' = 'POST'
    let url = 'lists/' + this.listIdent + '/words/'
    if (this.word) {
      method = 'PUT'
      url = 'lists/' + this.listIdent + '/words/' + this.word.ident
    }
    request(method, url, { phonics: this.phonicsData.join(','), words: [this.activeWord] })
      .then((response) => {
        const data = response.data
        console.log(data)
        this.closeView()
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }
}
